import axios from "axios";
import store from "@/store";
import APP_CONFIG from "@/apps/core/modules/config.js";
import ModelBase from "@/apps/core/models/modelBase.js";

const modelName = "auth";
const modelPath = "/api/token/";

class AuthModel extends ModelBase {
  constructor() {
    let model = {
      username: "",
      password: "",
    };
    let requiredFields = ["username", "password"];
    super(modelName, model, requiredFields, [], modelPath);
    this.userUrl = APP_CONFIG.baseAPIURL + "/anggota/me/";
  }

  initObservables() {
    super.initObservables();
    this.observables.needLogin = false;
    this.observables.loading = false;
  }

  setNeedLogin(value) {
    this.observables.needLogin = value;
  }

  getInitialErrorFields() {
    let errorMap = super.getInitialErrorFields();
    errorMap.detail = "";
    return errorMap;
  }

  clearNonFieldErrors() {
    this.observables.errorMap.non_field_errors = "";
    this.observables.errorMap.detail = "";
  }

  updateErrorFields(respErrorMap) {
    /* digunakan untuk load error dari response */
    this.validity.validated = false;
    this.validity.edited = false;
    for (const key of Object.keys(this.observables.errorMap)) {
      if (key in respErrorMap) {
        this.observables.errorMap[key] = respErrorMap[key];
      } else {
        this.observables.errorMap[key] = "";
      }
    }
  }

  setUser() {
    return axios.get(this.userUrl)
      .then(response => {
        return store.dispatch("accounts/setUser", response.data);  //.then(onSuccess);
      })
      .catch(error => {
        // tampilkan halaman login karena gagal load userdata.
        this.setNeedLogin(true);
        store.dispatch("accounts/resetAllState");
        return Promise.reject(error);
      });
  }

  fetchUser(onSuccess, onNeedLogin) {
    const hasToken = store.getters["accounts/isAuthenticated"];
    const noUserId = !store.state.accounts.userId;  // !this.userId;
    if (hasToken && noUserId) {
      this.setUser(onSuccess);
    } else {
      this.setNeedLogin(true);
      onNeedLogin();
    }
  }

  login() {
    const username = this.observables[this.modelName].username.toLowerCase();
    const password = this.observables[this.modelName].password;
    const payLoad = { username: username, password: password };
    this.observables.loading = true;
    return axios.post(this.apiURL, payLoad)
      .then(response => {
        const payLoad = {
          refreshToken: response.data.refresh,
          accessToken: response.data.access
        }
        store.dispatch("accounts/setToken", payLoad)
          .then(() => {
            let promise = this.setUser();
            this.observables.loading = false;
            return promise;
          });
      })
      .catch(error => {
        if (error.response.status === 401) {
          this.updateErrorFields(error.response.data);
          store.dispatch("accounts/resetAllState");
        }
        this.observables.loading = false;
        return Promise.reject(error);
      });
  }
}

export default AuthModel;